<template>
    <div class="container timeline_area about-page">
        <div class="row section_education">
            <div class="col-12">
                <!-- Section Heading-->
                <div class="section_heading">
                    <h3 class="text-start">Education</h3>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- Timeline Area-->
                <div class="apland-timeline-area">
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s"
                            style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p>2023 - Present</p>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa-solid fa-graduation-cap"></i>
                                    </div>
                                    <div class="timeline-text">
                                        <div class="work-title">Bachelor of Computer Science ( Software Development
                                            )</div>
                                        <li>Swinburne University of Technology</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s"
                            style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p>2022 - 2023</p>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa-solid fa-graduation-cap"></i>
                                    </div>
                                    <div class="timeline-text">
                                        <div class="work-title">Diploma of Information and Communication Technology.
                                        </div>
                                        <li>Swinburne University of Technology</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- End of Education Timeline -->

        <!-- Start of Work Experience Timeline -->
        <div class="row section_work">
            <div class="col-12 col-sm-8 col-lg-6">
                <!-- Section Heading-->
                <div class="section_heading">
                    <h3 class="text-start">Work Experience</h3>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- Timeline Area-->
                <div class="apland-timeline-area">
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s"
                            style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p>May 2022 - Jan 2024</p>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa-solid fa-briefcase"></i>
                                    </div>
                                    <div class="timeline-text">
                                        <div class="d-flex align-items-center work-place">
                                            <div class="work-title">Trianon Bakery and Cake Works</div>
                                            <div class="d-flex align-items-center work-role text-muted"><i
                                                    class="fa-solid fa-user"></i> Baker's Assistant</div>
                                            <div class="d-flex align-items-center work-location text-muted"><i
                                                    class="fa-solid fa-location-dot"></i> Hawthorn, Vic 3122</div>
                                        </div>
                                        <li>Provided excellent customer service by assisting customers with their
                                            orders and inquiries.</li>
                                        <li>Managed cash register operations and processed transactions accurately.
                                        </li>
                                        <li>Ensured the display of baked goods was attractive and well-stocked.</li>
                                        <li>Maintained cleanliness and organisation of the front-of-house area.</li>
                                        <li>Managed cash register operations and processed transactions accurately.
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s"
                            style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p>May 2022 - Dec 2023</p>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s"
                                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa-solid fa-briefcase"></i>
                                    </div>
                                    <div class="timeline-text">
                                        <div class="d-flex align-items-center work-place">
                                            <div class="work-title">Piquancy Resturant</div>
                                            <div class="d-flex align-items-center work-role text-muted"><i
                                                    class="fa-solid fa-user"></i> Waiter</div>
                                            <div class="d-flex align-items-center work-location text-muted"><i
                                                    class="fa-solid fa-location-dot"></i> Hawthorn, Vic 3122</div>
                                        </div>
                                        <!-- <h6 class="major">Piquancy Restaurant <span class="text-muted work-location"><i class="fa-solid fa-user"></i> Waiter</span><span class="text-muted work-location"> <i class="fa-solid fa-location-dot"></i> Hawthorn, Vic 3122</span></h6> -->
                                        <li>Greeted and seated customers, presented menus, and provided detailed
                                            information on dishes and specials</li>
                                        <li>Collaborated with kitchen staff for timely and accurate order
                                            fulfilment..</li>
                                        <li>Took accurate orders, delivered food and drinks, and ensured customer
                                            satisfaction.</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.about-page {
    margin-top: 20px;
    margin-bottom: 80px;
}

.timeline_area {
    position: relative;
    z-index: 1;
}

.single-timeline-area {
    position: relative;
    z-index: 1;
    padding-left: 180px;
}

.section_education,
.section_work {
    margin-top: 50px;
    margin-bottom: 30px;
}

.single-timeline-area .timeline-date {
    position: absolute;
    width: 180px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 60px;
}

@media only screen and (max-width: 575px) {
    .single-timeline-area .timeline-date {
        width: 120px;
    }
}

.single-timeline-area .timeline-date::after {
    position: absolute;
    width: 3px;
    height: 100%;
    content: "";
    background-color: #ebebeb;
    top: 0;
    right: 30px;
    z-index: 1;
}

.single-timeline-area .timeline-date::before {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #07afc2;
    content: "";
    top: 50%;
    right: 26px;
    z-index: 5;
    margin-top: -5.5px;
}


.single-timeline-area .timeline-date p {
    margin-bottom: 0;
    color: #020710;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
}


.single-timeline-area .single-timeline-content {
    position: relative;
    z-index: 1;
    padding: 30px 30px 25px;
    border-radius: 6px;
    margin-bottom: 15px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    border: 1px solid #ebebeb;
}

@media only screen and (max-width: 575px) {
    .single-timeline-area .single-timeline-content {
        padding: 20px;
    }
}

.single-timeline-area .single-timeline-content .timeline-icon {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 30px;
    height: 30px;
    background-color: #059ea9;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    text-align: center;
    max-width: 30px;
    border-radius: 50%;
    margin-right: 15px;
}

.single-timeline-area .single-timeline-content .timeline-icon i {
    color: #ffffff;
    line-height: 30px;
}

.single-timeline-area .single-timeline-content .timeline-text .work-title {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}


.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
    background-color: #020710;
}

.single-timeline-area .single-timeline-content:hover .timeline-text .work-title,
.single-timeline-area .single-timeline-content:focus .timeline-text .work-title {
    color: #b93107;
}

.single-timeline-area .single-timeline-content:hover .timeline-icon i,
.single-timeline-area .single-timeline-content:focus .timeline-icon i {
    color: #06d5ec
}


li {
    color: rgb(118, 116, 116);
    font-size: 13px;
    letter-spacing: 0.07rem;
    line-height: 1.4rem;
}

.work-place {
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
}

.work-title {
    letter-spacing: 0.1rem;
    font-weight: 550;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-right: 20px;
}

.work-role,
.work-location {
    font-size: 14px;
    color: rgb(194, 191, 191);
    font-weight: 600;
    letter-spacing: 0.1rem;
}

.work-role {
    margin-right: 20px;
}

.work-role i,
.work-location i {
    margin-right: 5px;
}

/* Media section */
@media only screen and (max-width: 768px) {

    .work-role,
    .work-location {
        margin-left: 0;
        margin-top: 5px;
    }
}

@media only screen and (max-width: 575px) {
    .single-timeline-area {
        padding-left: 100px;
    }

    .section_education,
    .section_work {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .section_work {
        margin-top: 50px;
    }
}
</style>